import { CSSProperties } from "react"

import { ANALYZE_SALARY_DISTRIBUTION } from "constants/text.constants"

import thousandSeparator from "utils/thousandSeparator"

import { tGroupPercentileData } from "./GroupPercentilesTable"

interface IExportTablePercentiles {
    groupPercentileData: tGroupPercentileData[]
}

const GroupPercentilesExportTable = ({ groupPercentileData }: IExportTablePercentiles) => {
    // const { AllEmployees, OnlyManagers, WithoutManagers } = diagramData

    // const fullDataArray = [AllEmployees, OnlyManagers, WithoutManagers]
    // const dataArray = fullDataArray

    const styles: { [key: string]: CSSProperties } = {
        tableStyle: {
            marginBottom: 48,
            borderSpacing: 0,
            borderCollapse: "collapse",
            textAlign: "left",
            height: "fit-content",
        },
        tbodyTd: {
            border: "1px solid #ddd",
            borderSpacing: 0,
            padding: 8,
        },
        theadTrTh: {
            padding: 8,
            background: "#ddd",
            border: "1px solid #ddd",
            borderSpacing: 0,
            fontFamily: "Open Sans Bold"
        },
    }

    return (
        <table className="invisible" style={styles.tableStyle}>
            <thead style={styles.theadTrTh}>
                <tr style={styles.theadTrTh}>
                    <th style={styles.theadTrTh}>{ANALYZE_SALARY_DISTRIBUTION.S3_TH1}</th>
                    <th style={styles.theadTrTh}>{ANALYZE_SALARY_DISTRIBUTION.S3_TH2}</th>
                    <th style={styles.theadTrTh}>{ANALYZE_SALARY_DISTRIBUTION.S3_TH3}</th>
                    <th style={styles.theadTrTh}>{ANALYZE_SALARY_DISTRIBUTION.S3_TH_MIN}</th>
                    <th style={styles.theadTrTh}>{ANALYZE_SALARY_DISTRIBUTION.S3_TH4}</th>
                    <th style={styles.theadTrTh}>{ANALYZE_SALARY_DISTRIBUTION.S3_TH5}</th>
                    <th style={styles.theadTrTh}>{ANALYZE_SALARY_DISTRIBUTION.S3_TH6}</th>
                    <th style={styles.theadTrTh}>{ANALYZE_SALARY_DISTRIBUTION.S3_TH7}</th>
                    <th style={styles.theadTrTh}>{ANALYZE_SALARY_DISTRIBUTION.S3_TH8}</th>
                    <th style={styles.theadTrTh}>{ANALYZE_SALARY_DISTRIBUTION.S3_TH_MAX}</th>
                    <th style={styles.theadTrTh}>{ANALYZE_SALARY_DISTRIBUTION.S3_TH9}</th>
                    <th style={styles.theadTrTh}>{ANALYZE_SALARY_DISTRIBUTION.S3_TH10}</th>
                    <th style={styles.theadTrTh}>{ANALYZE_SALARY_DISTRIBUTION.S3_TH11}</th>
                    <th style={styles.theadTrTh}>{ANALYZE_SALARY_DISTRIBUTION.S3_TH12}</th>
                </tr>
            </thead>
            <tbody>
                {groupPercentileData.map((row) => (
                    <tr key={`${row.GroupId}${Math.random() * 1600}`}>
                        <td style={styles.tbodyTd}>{row.Name}</td>
                        <td style={styles.tbodyTd}>{row.SalaryType.Value}</td>
                        <td style={styles.tbodyTd}>{thousandSeparator(row.Count.toString())}</td>
                        <td style={styles.tbodyTd}>{thousandSeparator(row.MinSalary.toString())}</td>
                        <td style={styles.tbodyTd}>{thousandSeparator(row.FirstTenthPercentile.toString())}</td>
                        <td style={styles.tbodyTd}>{thousandSeparator(row.FirstQuarterPercentile.toString())}</td>
                        <td style={styles.tbodyTd}>{thousandSeparator(row.MedianPercentile.toString())}</td>
                        <td style={styles.tbodyTd}>{thousandSeparator(row.LastQuarterPercentile.toString())}</td>
                        <td style={styles.tbodyTd}>{thousandSeparator(row.LastTenthPercentile.toString())}</td>
                        <td style={styles.tbodyTd}>{thousandSeparator(row.MaxSalary.toString())}</td>
                        <td style={styles.tbodyTd}>{thousandSeparator(row.AverageSalary.toString())}</td>
                        <td style={styles.tbodyTd}>{thousandSeparator(row.LastFirstTenthPercentile.toString())}</td>
                        <td style={styles.tbodyTd}>{thousandSeparator(row.LastTenthMedianPercentile.toString())}</td>
                        <td style={styles.tbodyTd}>{thousandSeparator(row.MedianFirstTenthPercentile.toString())}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default GroupPercentilesExportTable
