import "./BoxplotDiagram.css"
import { IboxplotDiagramData } from "types/sharedTypes"

import { useEffect, useState, useMemo } from "react"

import Highcharts from "highcharts"
import Exporting from "highcharts/modules/exporting"
import HighchartsReact from "highcharts-react-official"
import HighchartsMore from "highcharts/highcharts-more"
import HighchartsPatternFill from "highcharts/modules/pattern-fill"

import defaultSetOptions from "components/cores/highcharts/highchartsDefaultSetOptions"

const privateBoxPlotSerie = (
    Data: any,
    boolIsMoreThan20: boolean,
    // boolShowMinMax = false,
    // boolIsWageGapDiagram = false,
    normalizedSalaries = false
) => {
    const customPattern = {
        pattern: {
            path: {
                d: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
                strokeWidth: 4,
            },
            color: "#50A339",
            backgroundColor: "#83DB66",
            width: 10,
            height: 10,
        },
    }
    const customName = boolIsMoreThan20 ? "Grupp med fler än 20 individer" : "Grupp med 9-20 individer" 
    const customColor = boolIsMoreThan20 ? customPattern : "rgba(91, 167, 54, 1)"
    const customFillColor = boolIsMoreThan20 ? customPattern : "rgba(118, 199, 78, 1)"
    const customStemColor = boolIsMoreThan20 ? "rgba(65, 141, 28, 1)" : "rgba(118, 199, 78, 1)"

    const krPrefix = normalizedSalaries ? "" : "Kr"

    const customPointFormatter = boolIsMoreThan20
        ? `P90: {point.high} ${krPrefix}<br> P75: {point.q3} ${krPrefix}<br> P50: {point.median} ${krPrefix}<br> P25: {point.q1} ${krPrefix}<br> P10: {point.low} ${krPrefix}`
        : `P75: {point.q3} ${krPrefix}<br> P50: {point.median} ${krPrefix}<br> P25: {point.q1} ${krPrefix}`

    return {
        type: "boxplot",
        animation: false,
        name: customName,
        color: customColor,
        fillColor: customFillColor,
        medianColor: "rgba(255, 240, 175, 1)",
        whiskerColor: customStemColor,
        stemColor: customStemColor,
        lineWidth: 3,
        medianWidth: 2,
        width: 100,
        grouping: false,
        data: Data,
        tooltip: { pointFormat: customPointFormatter },
    }
}

const privateComparisonBoxPlot = (Data: number[][]) => {
    const Color = "rgba(143, 95, 193, 1)"

    const result = {
        type: "boxplot",
        name: "Referensstatistik",
        animation: false,
        color: Color,
        fillColor: "rgba(210, 167, 226, 1)",
        medianColor: "rgba(0, 64, 113, 1)",
        whiskerColor: Color,
        stemColor: Color,
        lineWidth: 3,
        medianWidth: 2,
        width: 100,
        grouping: false,
        data: Data,
        tooltip: {
            // eslint-disable-next-line object-shorthand
            pointFormatter: function (): string {
                const { low, q1, median, q3, high } = this as any
                if (low === q1 && high === q3) {
                    if (q1 === q3 && q1 === median) return `P50: ${median} Kr`
                    return `P75: ${q3} Kr<br> P50: ${median} Kr<br> P25: ${q1} Kr`
                }
                return `P90: ${high} Kr<br> P75: ${q3} Kr<br> P50: ${median} Kr<br> P25: ${q1} Kr<br> P10: ${low} Kr`
            },
        },
    }

    return result
}

const privateScatterChart = (Data: number[][], normalizedSalaries = false) => ({
    type: "scatter", 
    color: "rgba(143, 224, 103, 1)",
    name: "Grupp med färre än 9 individer", 
    data: Data,
    tooltip: {
        headerFormat: "{point.x}<br>",
        pointFormat: normalizedSalaries ? "Normaliserad lön: {point.y}" : "Lön: {point.y} Kr",
    },
})

// Add min and max dots as scatter series
const privateMinMaxDotScatterChart = (Data: number[][], Name: string, normalizedSalaries = false) => ({
    type: "scatter",
        name: Name,
        color: "rgba(0, 58, 100)",
        data: Data, 
        marker: {
            symbol: "circle",
            radius: 4, 
        },
        tooltip: {
            pointFormat: normalizedSalaries ? "Normaliserad lön: {point.y}" : "Lön: {point.y} Kr",
        },
        showInLegend: false,
})

const diagramSeries = (diagramData: number[][], boolShowMinMax = false) => {
    const moreThan20: number[][] = [];
    const lessThan21: number[][] = [];
    const lessThan9: number[][] = [];
    const minMaxDots21: number[][] = [];
    const minMaxDots20: number[][] = [];

    diagramData.forEach((arr: number[]) => {
        if (arr.length === 2) {
            lessThan9.push(arr);
        } else if (arr.length === 8) {
            // arr = [index, min, P10, P25, P50, P75, P90, max]; arr.length = 8
            // 7 values does not work (used to in old solution) so should be 5
            if (boolShowMinMax) {
                const newArray = [arr[0], arr[2], arr[3], arr[4], arr[5], arr[6]];
                moreThan20.push(newArray);

                minMaxDots20.push([arr[0], arr[1]]); // Min dot
                minMaxDots20.push([arr[0], arr[7]]); // Max dot
            } else {
                // If we want to show P10 and P90 as |-- and --| values, no min/max
                // newArray = [index, P10, P25, P50, P75, P90]
                const newArray = [arr[0], arr[2], arr[3], arr[4], arr[5], arr[6]];
                moreThan20.push(newArray);
            }
        } else if (arr.length === 6) {
            // Similar handling for arrays with 6 elements
            if (boolShowMinMax) {
                const newArray = [arr[0], arr[2], arr[2], arr[3], arr[4], arr[4]];
                lessThan21.push(newArray);

                minMaxDots21.push([arr[0], arr[1]]); // Min dot
                minMaxDots21.push([arr[0], arr[5]]); // Max dot
            } else {
                // Here the |-- wont be printed as we submit arr[2] twice, only show P25, P50, P75
                const newArray = [arr[0], arr[2], arr[2], arr[3], arr[4], arr[4]];
                lessThan21.push(newArray);
            }
        }
    });

    return [moreThan20, lessThan21, lessThan9, minMaxDots20, minMaxDots21];
};

const createSeries = (seriesData: number[][][], comparisonData: any[] = [], categories: string[] = [], normalizedSalaries = false, showMinMax = false) => {
    const seriesArray: any = [];

    if (seriesData[0].length !== 0) seriesArray.push(privateBoxPlotSerie(seriesData[0], true, normalizedSalaries)); // if more than 20
    if (seriesData[1].length !== 0) seriesArray.push(privateBoxPlotSerie(seriesData[1], false, normalizedSalaries)); // if 9-20
    if (seriesData[2].length !== 0) seriesArray.push(privateScatterChart(seriesData[2], normalizedSalaries)); // if less than 9

    if (showMinMax){
        // Add scatter series for min and max dots if there is data
        if (seriesData[3].length !== 0) {
            if (seriesData[3].length > 3){      
                const groupedData: { [key: string]: any[] } = seriesData[3].reduce((acc, item, index) => {
                    const categoryIndex = Math.floor(index / 2); // Get the category index by dividing the index by 2
                    const category = categories[categoryIndex] || "Gruppnamn"; // Fallback to "Gruppnamn" if categoryIndex exceeds array bounds
                  
                    // Group items by category
                    if (!acc[category]) {
                      acc[category] = [];
                    }
                    acc[category].push(item);
                  
                    return acc;
                  }, {} as { [key: string]: any[] }); // Explicitly define the type for the accumulator
                  
                  Object.entries(groupedData).forEach(([category, values]) => {
                    seriesArray.push(privateMinMaxDotScatterChart(values, category, normalizedSalaries));
                  });                  
            }
            else {
                seriesArray.push(privateMinMaxDotScatterChart(seriesData[3], categories[1], normalizedSalaries));
            }
        }
        if (seriesData[4].length !== 0) {
            if (seriesData[4].length > 2){
                const groupedData: { [key: string]: any[] } = seriesData[4].reduce((acc, item, index) => {
                    const categoryIndex = Math.floor(index / 2); // Get the category index by dividing the index by 2
                    const category = categories[categoryIndex] || "Gruppnamn"; // Fallback to "Gruppnamn" if categoryIndex exceeds array bounds
                  
                    // Group items by category
                    if (!acc[category]) {
                      acc[category] = [];
                    }
                    acc[category].push(item);
                  
                    return acc;
                  }, {} as { [key: string]: any[] }); // Explicitly define the type for the accumulator
                  
                  Object.entries(groupedData).forEach(([category, values]) => {
                    seriesArray.push(privateMinMaxDotScatterChart(values, category, normalizedSalaries));
                  });      
            }
            else {
                seriesArray.push(privateMinMaxDotScatterChart(seriesData[4], categories[2], normalizedSalaries));
            }
        }
    }

    if (comparisonData.length !== 0) seriesArray.push(privateComparisonBoxPlot(comparisonData));

    return seriesArray;
};

const getMinMax = (data: any) => {
    let min = 0
    let max = 0

    let allValues: any = []

    // removes the first item in each item
    data.forEach((arr: any) => {
        const newArr = arr.slice(1) // all items except the first one
        allValues = allValues.concat(newArr)
    })

    max = allValues.reduce((a: number, b: number) => Math.max(a, b))

    min = allValues.reduce((a: number, b: number) => Math.min(a, b))

    // Smaller gaps for wageGapStep 7
    if (max < 5000) {
        return [0, Math.ceil(max)]
    }

    const roundedMin = Math.floor(min / 5000) * 5000
    const roundedMax = Math.ceil(max / 5000) * 5000

    return [roundedMin, roundedMax]
}

type BoxplotDiagramProps = {
    diagramData: IboxplotDiagramData
    showMinorGridLines: boolean
    comparisonData?: any[]
    normalizedSalaries?: boolean
    showMinMax?: boolean
}

const BoxplotDiagram = ({
    diagramData,
    showMinorGridLines,
    normalizedSalaries,
    comparisonData = [],
    showMinMax = false,
}: BoxplotDiagramProps) => {
    Exporting(Highcharts)
    HighchartsMore(Highcharts)
    HighchartsPatternFill(Highcharts)

    Highcharts.setOptions(defaultSetOptions)

    // Memoize data calculations to avoid recomputing on every render
    const seriesData = useMemo(() => diagramSeries(diagramData.Data, showMinMax), [diagramData.Data, showMinMax]);
  const seriesArray = useMemo(() => createSeries(seriesData, comparisonData,  diagramData.Categories, normalizedSalaries, showMinMax), [seriesData, comparisonData, diagramData.Categories, normalizedSalaries, showMinMax]);
  const newMinMax = useMemo(() => getMinMax(seriesData.flat()), [seriesData]);

  const initialOptions = {
    chart: {
      inverted: true,
    },
    title: {
      text: diagramData.ChartTitle,
    },
    yAxis: {
      title: {
        text: diagramData.YAxisTitle,
      },
      lineWidth: 1,
      labels: {
        style: {
          fontSize: "14px",
        },
      },
    },
    xAxis: {
      categories: diagramData.Categories,
    },
    series: seriesArray,
    credits: {
      enabled: false,
    },
    plotOptions: {
        series: {
            stickyTracking: false,
            states: {
                inactive: {
                    enabled: false
                }
            }
        }
    },
  };

  const [chartOptions, setChartOptions] = useState(initialOptions);

  useEffect(() => {
    const updatedOptions = {
      ...chartOptions,
      title: { text: diagramData.ChartTitle },
      yAxis: {
        ...chartOptions.yAxis,
        min: normalizedSalaries ? newMinMax[0] : undefined,
        max: normalizedSalaries ? newMinMax[1] : undefined,
        minorTickInterval: showMinorGridLines ? "auto" : undefined,
      },
      series: seriesArray,
    };

    // Only update if the options are different
    if (JSON.stringify(chartOptions) !== JSON.stringify(updatedOptions)) {
      setChartOptions(updatedOptions);
    }
  }, [diagramData, seriesArray, newMinMax, normalizedSalaries, showMinorGridLines, chartOptions]);

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default BoxplotDiagram
