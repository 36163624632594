let apiEndpoint = null
let idpApiEndpoint = null

export const setapiendpoint = (url, urlIdp) => {
  apiEndpoint = url
  idpApiEndpoint = urlIdp
}

export const getapiendpoint = () => apiEndpoint

export const getIdpApiendpoint = () => idpApiEndpoint
