import "./SalarySelection.css"

import apiEndpoints  from "constants/endpoints.constants"
import GENERAL_TEXTS, { ANALYZE_SALARY_DISTRIBUTION, ANALYZE_WAGE_GAP } from "constants/text.constants"

import { useState, useEffect, useMemo, useContext, useRef } from "react"

import prepareCsrfToken from "utils/prepareCsrfToken"

import { postWithBodyAndCsrf } from "services/apiService"
import { fetchEmployeesInAGroup } from "services/fetchers"

import { Employees, Header } from "types/sharedTypes"

import { RegistriesContext } from "context/RegistriesContext"

import Error from "components/atoms/error/Error"
import Button from "components/atoms/button/Button"
import SliderButton from "components/atoms/sliderButton/SliderButton"
import RegisterTable from "components/cores/registerTable/RegisterTable"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"

import BoxplotDiagram from "components/cores/highcharts/boxplot/BoxplotDiagram"

import { ReactComponent as PDFIcon } from "assets/pdf.icon.svg"

import GroupPercentilesTable from "./groupPercentilesTable/GroupPercentilesTable"
import GroupPercentilesExportTable from "./groupPercentilesTable/GroupPercentilesExportTable"
import ScatterDiagram from "./scatterDiagram/ScatterDiagram"

import prepareBoxplotDiagramData from "./utils/prepareBoxplotDiagramData"

const headerDefaultValues = {
    Ssn: "Pnr",
    Name: "Namn",
    Number: "Anst",
    EmploymentScope: "Omf",
    WorkCode: "AA",
    GroupLevel: "G",
    Manager: "C",
    LokalKomplettering: "LL",
    Samråd: "S",
    YrkesSpecificering: "Y",
    KompetensKategori: "K",
    EmploymentStartDate: "Anstdat",
    SalaryBasic: "Grundlön",
    SalaryProvision: "Ft",
    SalaryFlexibleProvision: "Rt",
    SalaryOrganizationalAffiliation: "Org",
    CustomizableC1: "Fri1",
    CustomizableC2: "Fri2",
    CustomizableC4: "Fri4",
    CustomizableC5: "Fri5",
    CustomizableC3: "Fri3",
    CustomizableC6: "Fri6",
    CustomizableC7: "Fri7",
    CustomizableC8: "Fri8",
    CustomizableC9: "Fri9",
    CustomizableC10: "Fri10",
    IndividualAgreementsK70: "EÖ sl",
    IndividualAgreementsK71: "EÖ ls",
    IndividualAgreementsK72: "EÖ lp",
    IndividualAgreementsK73: "EÖ sp",
    Age: "Ålder",
    EmploymentLength: "Ansttid",
    Sex: "Kön",
}

interface ISlaraySelection {
    diagramType: number
    selectedRegisteryId: string
    salaryType: number
    selectedGroups: string[]
}

const SalarySelection = ({ diagramType, selectedRegisteryId, salaryType, selectedGroups }: ISlaraySelection) => {
    const { registries } = useContext(RegistriesContext)
    const selectedRegisterName =
        registries[registries.findIndex((register) => register.Id === selectedRegisteryId)].Name

    const [fetchError, setFetchError] = useState("")
    const [postError, setPostError] = useState("")

    const [tableRows, setTableRows] = useState<any[]>([])
    const [fetchingTableRows, setFetchingTableRows] = useState(true)

    const allRef = useRef<HTMLDivElement>(null)

    const [downloadingPDF, setDownloadingPDF] = useState(false)

    const [diagramData, setDiagramData] = useState<any>([])
    const [originalScatterData, setOriginalScatterData] = useState<any>([])
    const [fetchingDiagramData, setFetchingDiagramData] = useState(true)

    const [showGender, setShowGender] = useState(false)
    const [showMinMax, setShowMinMax] = useState(false)
    const [showMinorGridLines, setShowMinorGridLines] = useState(true)
    const [showGrouped, setShowGrouped] = useState(false)

    const [employees, setEmployees] = useState<Employees[]>([])
    const [employeesTableHeaders, setEmployeesTableHeaders] = useState<Header>(headerDefaultValues)
    const [fetchingEmployeesInAGroup, setFetchingEmployeesInAGroup] = useState(true)

    const [groupName, setGroupName] = useState("")

    const [selectedGrouping, setSelectedGrouping] = useState("Anställda")
    const diagramGrouping = [
        { Key: 1, Value: "Anställda" },
        { Key: 0, Value: "Åldersgrupp" },
        { Key: 2, Value: "Omfattning" },
        { Key: 3, Value: "Anställningstid (År)" },
        { Key: 4, Value: "Kön" },
        { Key: 5, Value: "Ålder" },
    ]
    const groupingOptions = ["Åldersgrupp", "Omfattning", "Anställningstid (År)", "Kön", "Ålder"]
    const salaryTypes = useMemo(() => [null, "Grundlön", null, null, "Fast lön", "Totallön"], [])

    const toggleGrouping = () => {
        setShowGrouped(!showGrouped)
        setSelectedGrouping(showGrouped ? "Anställda" : "Åldersgrupp")
        if (showGrouped) setDiagramData(originalScatterData)
    }

    const downloadPDF = () => {
        const html: any = []
        let string = ""
        setDownloadingPDF(true)
        setPostError("")

        // We use style={{}} object instead on plain css in this file and files that needs to be exported due to how endpoint is written
        // Refactor the BE endpoint to recognize both className and style.

        if (allRef.current) {
            const parent: { id: string }[] = [...allRef.current.children]
            parent.forEach((element) => {
                if (element.id !== "excludeFromPDF") html.push(element)
            })
            html.forEach((el: any) => {
                string += el.outerHTML
            })

            prepareCsrfToken().then((csrfToken) =>
                postWithBodyAndCsrf(apiEndpoints().analyzeSalaryExportPdf, csrfToken, JSON.stringify({ Htmltext: string }), true)
                    .then((response) => {
                        setDownloadingPDF(false)
                        const filename = "Löneläge & lönespridning.pdf"

                        response.blob().then((blob: any) => {
                            const url = window.URL.createObjectURL(new Blob([blob]))
                            const link = document.createElement("a")
                            link.setAttribute("href", url)
                            link.setAttribute("download", filename)
                            document.body.appendChild(link)
                            link.click()
                        })
                    })
                    .catch(() => {
                        setDownloadingPDF(false)
                        setPostError(GENERAL_TEXTS.ERROR_TEXT_SAVING)
                    })
            )
        }
    }

    // Get Table data
    useEffect(() => {
        const body = { GroupIds: selectedGroups, SalaryType: salaryType }

        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(apiEndpoints().analyzeGetSalaryTableData, csrfToken, JSON.stringify(body))
                .then((response) => {
                    setTableRows(response)
                    setGroupName(response[0].Name)
                    setFetchingTableRows(false)
                    setPostError("")
                })
                .catch(() => setPostError(GENERAL_TEXTS.POST_ERROR_GENERIC))
        )
    }, [salaryType, selectedGroups])

    // Get Diagram data
    useEffect(() => {
        const body = { GroupIds: selectedGroups, SalaryType: salaryType, DiagramType: diagramType }
        // const salaryTypes = [null, "Grundlön", null, null, "Fast lön", "Totallön"]

        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(apiEndpoints().analyzeSalaryDiagram, csrfToken, JSON.stringify(body))
                .then((response) => {
                    if (diagramType === 1 || response.DiagramData === "A") {
                        const chartTitle = ANALYZE_SALARY_DISTRIBUTION.S3_DIAGRAM_TITLE
                        const yAxisTitle = salaryTypes[salaryType] || ""
                        const preparedDiagramData = prepareBoxplotDiagramData(
                            response.DiagramDataList,
                            chartTitle,
                            yAxisTitle
                        )

                        setDiagramData(preparedDiagramData)
                    }
                    if (diagramType === 2) {
                        setDiagramData(JSON.parse(response.DiagramData))
                        setOriginalScatterData(JSON.parse(response.DiagramData))
                    }

                    setFetchingDiagramData(false)
                    setPostError("")
                })
                .catch(() => {
                    setFetchingDiagramData(false)
                    setPostError(GENERAL_TEXTS.POST_ERROR_GENERIC)
                })
        )
    }, [salaryType, diagramType, selectedGroups, salaryTypes])

    useEffect(() => {
        if (diagramType === 2)
            fetchEmployeesInAGroup(
                selectedGroups[0],
                setEmployees,
                setEmployeesTableHeaders,
                setFetchingEmployeesInAGroup,
                setFetchError
            )
    }, [diagramType, selectedGroups])

    return (
        <>
            {fetchingDiagramData && fetchingTableRows && fetchingEmployeesInAGroup && !fetchError && (
                <ContentSkeleton />
            )}
            {fetchError && <Error>{fetchError}</Error>}
            {!fetchingDiagramData && !fetchingTableRows && !fetchError && (
                <div ref={allRef}>
                    <h2>{ANALYZE_SALARY_DISTRIBUTION.S3_H2}</h2>
                    <div style={{ display: "flex", flexWrap: "wrap", gap: 16 }}>
                        {diagramType === 2 && groupName && (
                            <p style={{ display: "flex", flexWrap: "wrap" }}>
                                <strong>{ANALYZE_SALARY_DISTRIBUTION.S3_DIAGRAM_GROUP}</strong>
                                {groupName}
                            </p>
                        )}
                        <p style={{ display: "flex", flexWrap: "wrap" }}>
                            <strong>{ANALYZE_SALARY_DISTRIBUTION.S3_DIAGRAM_SALARY_TYPE}</strong>
                            {salaryTypes[salaryType]}
                        </p>
                        <p style={{ display: "flex", flexWrap: "wrap" }}>
                            <strong>{ANALYZE_SALARY_DISTRIBUTION.S3_DIAGRAM_REGISTER}</strong>
                            {selectedRegisterName}
                        </p>
                    </div>
                    <div id="excludeFromPDF" className="flex wrap gap36 mb16 jc-space-between ai-flex-start">
                        <div className="flex gap20 ai-flex-start">
                            <div className="sliderButtonsContainer">
                                {diagramType === 1 && (
                                    <SliderButton checked={showMinMax} setChecked={setShowMinMax}>
                                        {ANALYZE_SALARY_DISTRIBUTION.S3_SLIDER_MIN_MAX}
                                    </SliderButton>
                                )}
                                {diagramType === 2 && (
                                    <SliderButton checked={showGender} setChecked={setShowGender}>
                                        {ANALYZE_SALARY_DISTRIBUTION.S3_SLIDER_MAN_WOMEN}
                                    </SliderButton>
                                )}
                                <SliderButton checked={showMinorGridLines} setChecked={setShowMinorGridLines}>
                                    {ANALYZE_SALARY_DISTRIBUTION.S3_SLIDER_MINOR_GRID}
                                </SliderButton>
                            </div>
                            {diagramType === 2 && (
                                <SliderButton checked={showGrouped} setChecked={toggleGrouping}>
                                    {ANALYZE_SALARY_DISTRIBUTION.S3_SLIDER_SHOW_GROUPED}
                                </SliderButton>
                            )}
                            {diagramType === 2 && showGrouped && (
                                <select
                                    onChange={(e) => setSelectedGrouping(e.target.value)}
                                    name="select"
                                    id="select"
                                    className="registryDropdown selectMemberSelect"
                                >
                                    {groupingOptions.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </div>
                        <Button Icon={PDFIcon} onClick={downloadPDF} isLoading={downloadingPDF}>
                            {ANALYZE_WAGE_GAP.DIAGRAM_MODAL_EXPORT_PDF}
                        </Button>
                    </div>
                    {diagramType === 1 && !fetchingDiagramData && !!diagramData.Data.length && (
                        <BoxplotDiagram diagramData={diagramData} showMinorGridLines={showMinorGridLines} showMinMax={showMinMax} />
                    )}
                    {diagramType === 2 && !fetchingDiagramData && !!diagramData.length && (
                        <ScatterDiagram
                            diagramData={diagramData}
                            diagramTitle={ANALYZE_SALARY_DISTRIBUTION.S3_DIAGRAM_TITLE}
                            showMinorGridLines={showMinorGridLines}
                            showGender={showGender}
                            diagramGrouping={diagramGrouping}
                            selectedGrouping={selectedGrouping}
                        />
                    )}
                    {tableRows && (
                        <div id="excludeFromPDF">
                            <GroupPercentilesTable groupPercentileData={tableRows} showMinMax={showMinMax} />
                        </div>
                    )}
                    {tableRows && (
                        <div className="invisibl">
                            <GroupPercentilesExportTable groupPercentileData={tableRows} />
                        </div>
                    )}
                    {diagramType === 2 && employees && !fetchingEmployeesInAGroup && (
                        <div id="excludeFromPDF" style={{ height: 600 }}>
                            <RegisterTable header={employeesTableHeaders} employees={employees} />
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export default SalarySelection
