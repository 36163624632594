import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"

import apiEndpoints  from "constants/endpoints.constants"
import pageIds from "constants/pageIds.constants.json"
import pageTitles from "constants/pageTitles.constants.json"
import GENERAL_TEXTS, { GROUPS_TEXTS, REGISTER_TEXTS } from "constants/text.constants"

import { Employees, groupTable, Header } from "types/sharedTypes"

import { useContext, useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import { AgGridReact } from "ag-grid-react"
import { RowClickedEvent } from "ag-grid-community"

import ReactModal from "react-modal"

import prepareCsrfToken from "utils/prepareCsrfToken"
import { AG_GRID_LOCALE_SV } from "utils/locales/ag-grid.locale.sv"
import KeyboardNavigation from "utils/keyboardNavigation/KeyboardNavigation"

import useScrollToTop from "hooks/useScrollToTop"
import useUpdatePageTitle from "hooks/useUpdatePageTitle"

import { fetchGroups, fetchRegistries } from "services/fetchers"
import { deleteWithBodyAndCsrf } from "services/apiService"

import { RegistriesContext } from "context/RegistriesContext"

import Error from "components/atoms/error/Error"
import Button from "components/atoms/button/Button"
import Warning from "components/atoms/warning/Warning"
import PageHeader from "components/cores/pageHeader/PageHeader"
import Confirmation from "components/atoms/confirmation/Confirmation"
import RegisterTable from "components/cores/registerTable/RegisterTable"
import SelectRegistry from "components/cores/selectRegistry/SelectRegistry"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"

import { ReactComponent as xMark } from "assets/xmark.icon.svg"

import Tab from "./components/tab/Tab"
import Tabs from "./components/tabs/Tabs"
import ButtonContainer from "./components/ButtonContainer"
import TableButtonShow from "./components/TableButtonShow"

interface IRegistryData {
    Count: number
    Created: string
    GroupType: { Key: number; Value: string }
    Id: string
    Name: string
    RegistryId: string
}

type SelectedRowObject = {
    Antal: number
    GroupId: string
    Grupp: string
    Grupptyp: string
}

const ShowGroupsPage = () => {
    const { registries, setRegistries, registriesFetched, setRegistriesFetched } = useContext(RegistriesContext)
    const location = useLocation()
    const { locationRegistryId } = location.state || {}
    const [groupTableData, setGroupTableData] = useState<IRegistryData[]>([])
    const [groupName, setGroupName] = useState("")
    const [registryId, setRegistryId] = useState(
        locationRegistryId || (registriesFetched && registries.length ? registries[0].Id : "")
    )
    const [noRegisters, setNoRegisters] = useState(!registries.length && registriesFetched)
    const [activeIndex, setActiveIndex] = useState(1)
    const [employees, setEmployees] = useState<Employees[]>()
    const [employeesTableHeaders, setEmployeesTableHeaders] = useState<Header>()
    const [showModal, setShowModal] = useState(false)
    const [selectedGroups, setSelectedGroups] = useState<string[]>([])
    const [fetchError, setFetchError] = useState("")
    const [fetchingRegistries, setFetchingRegistries] = useState(!registries.length)
    const [fetchingGroups, setFetchingGroups] = useState(!registries.length)
    const [fetchingEmployeesInAGroup, setFetchingEmployeesInAGroup] = useState(true)
    const [postError, setPostError] = useState("")
    const [showWarning, setShowWarning] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const localeText = useMemo(() => AG_GRID_LOCALE_SV, [])
    const defaultColDef = KeyboardNavigation()

    const createColumnDefs = (theCellRenderer: any, theCellRendererParams: any) => [
        {
            field: GROUPS_TEXTS.GROUPS_TH1,
            sortable: true,
            filter: true,
            flex: 2,
        },
        {
            field: GROUPS_TEXTS.GROUPS_TH2,
            sortable: true,
            filter: true,
            flex: 2,
        },
        {
            field: GROUPS_TEXTS.GROUPS_TH3,
            sortable: true,
            filter: true,
            flex: 2,
        },
        {
            field: GROUPS_TEXTS.GROUPS_TH4,
            sortable: true,
            filter: true,
            cellRenderer: theCellRenderer,
            cellRendererParams: theCellRendererParams,
            flex: 2,
        },
    ]

    const [columnDefs] = useState(
        createColumnDefs(TableButtonShow, {
            setEmployees,
            setEmployeesTableHeaders,
            setShowModal,
            setGroupName,
            setFetchError,
            setFetchingEmployeesInAGroup,
        })
    )

    const [columnDefsManualGroups, setColumnDefsManualGroups] = useState(
        createColumnDefs(ButtonContainer, {
            registryId,
            registries,
            setEmployees,
            setEmployeesTableHeaders,
            setShowModal,
            setGroupName,
            setFetchError,
            setFetchingEmployeesInAGroup,
        })
    )

    const [defaultGroups, setDefaultGroups] = useState<groupTable[]>([])
    const [filterGroups, setFilterGroups] = useState<groupTable[]>([])
    const [manualGroups, setManualGroups] = useState<groupTable[]>([])

    const [groupNames, setGroupNames] = useState<string[]>([])

    const onSelectionChanged = (event: RowClickedEvent) => {
        const groupIds: string[] = []
        const groups: string[] = []
        const selectedRows = event.api.getSelectedRows()

        selectedRows.forEach((group: SelectedRowObject) => {
            groupIds.push(group.GroupId)
            groups.push(group.Grupp)
        })
        setSelectedGroups(groupIds)
        setGroupNames(groups)
        setShowWarning(false)
    }

    const deleteGroups = () => {
        prepareCsrfToken().then((csrfToken) =>
            deleteWithBodyAndCsrf(apiEndpoints().deleteGroups, csrfToken, JSON.stringify({ GroupIds: selectedGroups }))
                .then(() => {
                    // setFilterGroups((prevState) => {
                    //     if (!prevState) return prevState

                    //     selectedGroups.forEach((groupToRemove) => {
                    //         const index = prevState.findIndex((item) => item.GroupId === groupToRemove)
                    //         if (index > -1) {
                    //             prevState.splice(index, 1)
                    //             return prevState
                    //         }
                    //         return prevState
                    //     })
                    //     return prevState
                    // })
                    // setFilterGroups([...filterGroups])
                    fetchGroups(
                        registryId,
                        setGroupTableData,
                        setDefaultGroups,
                        setFilterGroups,
                        setManualGroups,
                        setFetchingGroups,
                        setFetchError
                    )

                    // Change when groupstable update works
                    if (
                        (activeIndex === 2 && selectedGroups.length === filterGroups.length) ||
                        (activeIndex === 3 && selectedGroups.length === manualGroups.length)
                    ) {
                        setActiveIndex(1)
                    }

                    setSelectedGroups([])
                    setShowWarning(false)
                    setShowConfirmation(true)
                    setTimeout(() => {
                        setShowConfirmation(false)
                    }, 5000)
                })
                .catch((err) => {
                    setShowWarning(false)
                    setShowConfirmation(false)
                    if (err) setPostError(GENERAL_TEXTS.POST_ERROR_GENERIC)
                })
        )
    }

    const closeModal = () => {
        setShowModal(false)
        setFetchError("")
    }

    const avaliableGroups = [
        { group: defaultGroups, type: "Auto", name: GROUPS_TEXTS.GROUPS_REGISTRIES },
        { group: filterGroups, type: "Filter", name: GROUPS_TEXTS.GROUPS_FILTER },
        { group: manualGroups, type: "Manuell", name: GROUPS_TEXTS.GROUPS_MANUAL },
    ]

    useEffect(() => {
        if (!registries.length && !registriesFetched)
            fetchRegistries(
                setRegistries,
                setNoRegisters,
                setRegistryId,
                setFetchingRegistries,
                setRegistriesFetched,
                setFetchError
            )
    }, [registries, setRegistries, registriesFetched, setRegistriesFetched])

    useEffect(() => {
        if (registries.length > 0 && registryId)
            fetchGroups(
                registryId,
                setGroupTableData,
                setDefaultGroups,
                setFilterGroups,
                setManualGroups,
                setFetchingGroups,
                setFetchError
            )
        setActiveIndex(1)
        setColumnDefsManualGroups(
            createColumnDefs(ButtonContainer, {
                registryId,
                registries,
                setEmployees,
                setEmployeesTableHeaders,
                setShowModal,
                setGroupName,
                setFetchError,
                setFetchingEmployeesInAGroup,
            })
        )
    }, [registries, registryId])

    useUpdatePageTitle(pageTitles.SHOW_GROUPS_PAGE_TITLE)
    useScrollToTop()

    useEffect(() => {
        setShowWarning(false)
        setGroupNames([])
        setSelectedGroups([])
    }, [registryId])

    const handleTabChange = (index: number) => {
        setShowWarning(false)
        setActiveIndex(index)
        setSelectedGroups([])
        setGroupNames([])
    }

    return (
        <>
            <PageHeader pageId={pageIds.SHOW_GROUPS_PAGE_ID} />
            {fetchError && <Error>{fetchError}</Error>}
            {noRegisters && <p>{REGISTER_TEXTS.NO_REGISTERS}</p>}
            {!fetchError && fetchingRegistries && !noRegisters && <ContentSkeleton />}
            {!fetchError && !fetchingRegistries && registries && !noRegisters && (
                <SelectRegistry registries={registries} setRegistryId={setRegistryId} defaultValue={registryId} />
            )}
            {!fetchError && fetchingGroups && !noRegisters && <ContentSkeleton />}
            {!fetchError && !fetchingGroups && !noRegisters && avaliableGroups.length > 0 && (
                <Tabs>
                    {avaliableGroups.map(({ group, type, name }, index) => (
                            <Tab
                                key={type}
                                name={name}
                                index={index + 1}
                                activeIndex={activeIndex}
                                setActiveIndex={(i: number) => handleTabChange(i)}
                            /> 
                    ))}
                </Tabs>
            )}
            {/* For separated component we need: activeIndex, ... */}
            {/* Possible it would be better to have 1 agGrid table and redifine the rows/cols based active step */}
            {activeIndex === 1 && defaultGroups && !fetchingGroups && (
                <div
                    className="ag-theme-alpine"
                    style={{ width: "100%", height: "50vh", marginTop: "16px", maxWidth: "1000px" }}
                >
                    <AgGridReact
                        rowData={defaultGroups}
                        defaultColDef={defaultColDef}
                        columnDefs={columnDefs}
                        animateRows
                        localeText={localeText}
                        alwaysShowHorizontalScroll
                        alwaysShowVerticalScroll
                        suppressMenuHide
                    />
                </div>
            )}
            {activeIndex === 2 && !!filterGroups.length && !fetchingGroups && (
                <>
                    <div
                        className="ag-theme-alpine"
                        style={{
                            width: "100%",
                            height: "50vh",
                            marginTop: "16px",
                            marginBottom: "16px",
                            maxWidth: "1000px",
                        }}
                    >
                        <AgGridReact
                            onCellClicked={onSelectionChanged}
                            rowData={filterGroups}
                            defaultColDef={defaultColDef}
                            columnDefs={columnDefs}
                            localeText={localeText}
                            animateRows
                            rowSelection="multiple"
                            alwaysShowHorizontalScroll
                            alwaysShowVerticalScroll
                            suppressMenuHide
                        />
                    </div>
                    <Button onClick={() => setShowWarning(true)} disabled={!selectedGroups.length} variant="delete">
                        {GROUPS_TEXTS.BUTTON_DELETE_GROUPS}
                    </Button>
                </>
            )}
            {activeIndex === 3 && !!manualGroups.length && !fetchingGroups && (
                <>
                    <div
                        className="ag-theme-alpine"
                        style={{
                            width: "100%",
                            height: "50vh",
                            marginTop: "16px",
                            marginBottom: "16px",
                            maxWidth: "1000px",
                        }}
                    >
                        <AgGridReact
                            onCellClicked={onSelectionChanged}
                            rowData={manualGroups}
                            defaultColDef={defaultColDef}
                            columnDefs={columnDefsManualGroups}
                            localeText={localeText}
                            animateRows
                            rowSelection="multiple"
                            alwaysShowHorizontalScroll
                            alwaysShowVerticalScroll
                            suppressMenuHide
                        />
                    </div>
                    <Button onClick={() => setShowWarning(true)} disabled={!selectedGroups.length} variant="delete">
                        {GROUPS_TEXTS.BUTTON_DELETE_GROUPS}
                    </Button>
                </>
            )}
            {/* For separated component we need: showModal, closeModal, groupName, fetchError, fetchingEmployeesInAGroup, employees, employeesTableHeaders */}
            {showModal && (
                <ReactModal
                    appElement={document.getElementById("root") as HTMLElement}
                    isOpen={showModal}
                    onRequestClose={closeModal}
                    contentLabel={GROUPS_TEXTS.MODAL_CONTENT}
                >
                    {fetchingEmployeesInAGroup && !fetchError && <ContentSkeleton />}
                    {fetchError && <Error>{fetchError}</Error>}
                    {!fetchingEmployeesInAGroup && !fetchError && employees && employeesTableHeaders && (
                        <>
                            <div className="headingAndCloseButtonFlex">
                                <h1>{`${GROUPS_TEXTS.MODAL_HEADER}${groupName}`}</h1>
                                <Button Icon={xMark} iconRight onClick={() => setShowModal(false)}>
                                    {GENERAL_TEXTS.BUTTON_CLOSE}
                                </Button>
                            </div>

                            <RegisterTable header={employeesTableHeaders} employees={employees} />
                        </>
                    )}
                </ReactModal>
            )}
            <div className="maxWidth1000">
                {showWarning && (
                    <Warning onClick={() => deleteGroups()} showWarning={setShowWarning} variant="delete">
                        {`${GROUPS_TEXTS.WARNING_DELETE} ${groupNames.toString().replace(/,/g, ", ")}`}
                    </Warning>
                )}
                {showConfirmation && <Confirmation>{GROUPS_TEXTS.CONFIRMATION_DELETED}</Confirmation>}
                {postError && <Error>{postError}</Error>}
            </div>
        </>
    )
}

export default ShowGroupsPage
